import request from '@/utils/axios-request'
// 文章列表
export const apiArticleList = (data: any) => {
  return request({
    url: '/article/api/article-list',
    method: 'post',
    data
  })
}
// 获取文章
export const apiGetArticle = (params: any) => {
  return request({
    url: `/article/api/get-article/${params.name}`,
    method: 'get'
  })
}
